
//二进制流导出
export function handlerExport(res) {
  let url = window.URL.createObjectURL(res.data);
  let a = document.createElement("a");
  let fileName = res.headers["content-disposition"].split("filename=")[1];
  a.href = url;
  a.download = fileName ? decodeURIComponent(fileName, 'utf-8') : "导出数据_" + new Date().getTime() + ".xlsx"; //自定义导出文件名
  a.click();
}

// 重命名多个对象键
export function renameKeys(obj, keyMap) {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = keyMap[key] || key;
    acc[newKey] = obj[key];
    return acc;
  }, {});
}
export function throttle(func, delay) {
  var delay = delay || 1000;
  var previousDate = new Date();
  var previous = previousDate.getTime();  // 初始化一个时间，也作为高频率事件判断事件间隔的变量，通过闭包进行保存。
  
  return function(...args) {
      var context = this;
      var nowDate = new Date();
      var now = nowDate.getTime();
      if (now - previous >= delay) {  // 如果本次触发和上次触发的时间间隔超过设定的时间
          func.call(context, ...args);  // 就执行事件处理函数 （eventHandler）
          previous = now;  // 然后将本次的触发时间，作为下次触发事件的参考时间。
      }
  }
}