<template>
  <el-menu
    :router="true"
    :default-active="activeMenu"
    class="el-menu-vertical-demo"
    :unique-opened="true"
  >
    <!-- <div v-for="(item, index) in routesList" :key="index">
        <component
        v-if="!item.isHide"
      :is="
        item.children && item.children.length && !item.meta?.level? 'el-submenu' : 'el-menu-item'
      "
      :index="item.fullPath || index + 11 + '1'"
    >
      <span slot="title">{{ item.meta?.title }}</span>
      <template v-if="item.children && item.children.length && !item.meta.level ">
        <submenu-item :nodes="item.children"></submenu-item>
      </template>
    </component>
    </div> -->
    <sidebar-item v-for="(route) in routesList" :key="route.path" :item="route" :base-path="route.path"></sidebar-item>
  </el-menu>
</template>

<script>
import SubmenuItem from "./components/item.vue";
import SidebarItem from './components/SidebarItem'
import Layout from '@/layout'
export default {
  components: { SubmenuItem , Layout, SidebarItem },
  data() {
    return {};
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    routesList() {
      return this.$store.getters["permission/getRoutes"];
    },
  },
};
</script>

<style>
.el-menu{
  border: 0;
}
.el-menu-vertical-demo {
  width: 140px;
}
.el-menu-item,
.el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
}
.el-submenu .el-menu-item {
  min-width: 100px !important;
  height: 40px !important;
  line-height: 40px !important;
}
</style>
