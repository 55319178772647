import Vue from 'vue';
import Vuex from 'vuex';
// 需要使用插件
Vue.use(Vuex)
// 引入模块仓库
import order from './order'
import user from './user';
import message from './message';
import app from './app'
import company from './company';
import permission from './permission';
import goods from './goods';
import payment from './payment';
//对外暴露
export default new Vuex.Store({
  //实现Vuex 仓库模块
  modules: {
    order,
    user,
    message,
    app,
    company,
    permission,
    goods,
    payment
  }
})