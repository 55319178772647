// 全局指令
const throttle = {
  inserted: function (el) {
    let timer = null
    el.addEventListener('click', () => {
      if (timer) {
        return false
      }
      timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
      }, 1000)
    })
  },
}

export default throttle