import axios from "./request";
import config from '@/config/index'

/**
 * 账号登录
 * @param {*} data 
 * @returns 
 */
export function login(data) {
  return axios({
    url: `${config.baseUrl}/account/manage/login/account`,
    method: 'post',
    data
  })
}

/**
 * 获取用户信息
 * @returns 
 */
export function getInfo() {
  return axios({
    url: `${config.baseUrl}/account/info`,
    method: 'get'
  })
}

/**
 * 重置密码
 * @param {*} data 
 * @returns 
 */
export function RetrievePassword(data) {
  return axios({
    url: `${config.baseUrl}/account/password/retrieve`,
    method: 'post',
    data
  })
}