<template>
  <el-input-number
    v-model="id"
    :min="0"
    v-bind="$attrs"
    @change="handleChange"
  ></el-input-number>
</template>

<script>
export default {
  name: "YlInputNumber",
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    id: {
      get() {
        return this.value
      },
      set(id) {
        this.$emit('input', this.formatValue(id))
      }
    }
  },
  data() {
    return {};
  },
  methods:{
    formatValue(value) {
      // 自定义格式化函数
      if (value === null || value === '') {
        return value;
      }
      const parts = String(value).split('.'); // 将值拆分为整数部分和小数部分
      if (parts[1] && parts[1].length > 6) { // 如果小数部分超过6位，则只保留6位
        parts[1] = parts[1].slice(0, 6); // 使用 slice 方法截取小数部分的前六个字符
      }
      return Number(parts.join('.')); 
    },
    handleChange() {
      this.$emit('inputChange',...arguments);  // 发出自定义事件和值
    }
  }
};
</script>

<style></style>
