// 供应链管理
import Layout from '@/layout'
import store from '@/store';

const {purchase, sell} = store.state.app.identity
const scm = {
	path: '/scm',
	component: Layout,
	redirect: '/scm/sell',
	name: 'SCM',
	meta: {
		title: '供应链管理',
	},
	children: [
		{
			path: 'seller',
			component: () => import('@/views/scm/index'),
			name: 'SCMSeller',
			parentName: 'SCM',
			meta: {
				title: '上游供应商', Identity: sell,
			},
			props: {Identity: sell}
		},
		{
			path: 'purchase',
			component: () => import('@/views/scm/index'),
			name: 'SCMPurchase',
			parentName: 'SCM',
			meta: {
				title: '下游采购商', Identity: purchase,
			},
			props: {Identity: purchase}
		}
	]
}

export default scm
