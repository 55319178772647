import Layout from '@/layout'
import Empty from '@/components/empty.vue'
import store from '@/store';
const { purchase, sell } = store.state.app.identity
const financialManagementRouter = {
  path: '/financialManagement',
  component: Layout,
  name: 'financialManagement',
  redirect: '/financialManagement/basic-setting',
  meta: {
    title: '财务管理',
  },
  children: [
    {
      path: 'settlement-manage',
      component: Empty,
      name: 'settlementManage',
      redirect: '/financialManagement/settlement-manage/settlement-creation',
      parentName: 'financialManagement',
      meta: {
        title: '结算管理',
      },
      children: [
        {
          path: 'settlement-creation',
          name: 'settlementCreation',
          parentName: 'settlementManage',
          fullPath: '/financialManagement/settlement-manage/settlement-creation',
          component: () => import('@/views/financialManagement/SettlementManagement/StatementCreation'),
          meta: { title: '结算单创建' }
        },
        {
          path: 'statement/:id/:type?',
          name: 'statement',
          parentName: 'settlementManage',
          fullPath: '/financialManagement/settlement-manage/statement',
          component: () => import('@/views/financialManagement/SettlementManagement/Statement'),
          meta: { title: '生成结算单', activeMenu: '/financialManagement/settlement-manage/settlement-creation' },
          isHide: true,
          props: true,
          beforeEnter: (to, from, next) => {
            if (to.params.type === 'purchase') {
              to.meta.title = '采购结算单'
              to.meta.activeMenu = '/financialManagement/settlement-manage/purchase-statement'
            } else if (to.params.type === 'sell') {
              to.meta.title = '销售结算单'
              to.meta.activeMenu = '/financialManagement/settlement-manage/sales-statement'
            } else if (to.params.type === 'delivery') {
              to.meta.title = '到货结算单'
              to.meta.activeMenu = '/financialManagement/settlement-manage/delivery-statement'
            } else {
              to.meta.title = '生成结算单'
              to.meta.activeMenu = '/financialManagement/settlement-manage/settlement-creation'
            }
            next()
          }
        },
        // 采购结算单
        {
          path: 'purchase-statement',
          name: 'purchaseStatement',
          parentName: 'settlementManage',
          fullPath: '/financialManagement/settlement-manage/purchase-statement',
          component: () => import('@/views/financialManagement/SettlementManagement/List'),
          meta: { title: '采购结算单', Identity: purchase },
          props: { Identity: purchase, generation: 1}
        },
        // 销售结算单
        {
          path: 'sales-statement',
          name: 'salesStatement',
          parentName: 'settlementManage',
          fullPath: '/financialManagement/settlement-manage/sales-statement',
          component: () => import('@/views/financialManagement/SettlementManagement/List'),
          meta: { title: '销售结算单', Identity: sell },
          props: { Identity: sell, generation: 1}
        },
        // 到货结算单
        {
          path: 'delivery-statement',
          name: 'deliveryStatement',
          parentName: 'settlementManage',
          fullPath: '/financialManagement/settlement-manage/delivery-statement',
          component: () => import('@/views/financialManagement/SettlementManagement/List'),
          meta: { title: '到货结算单'},
          props: { generation: 2}
        }
      ]
    },
    // 入账管理
    {
      path: 'receivables-manage',
      name: 'receivablesManage',
      parentName: 'financialManagement',
      component: Empty,
      meta: {
        title: '入账管理',
      },
      children: [
        // 采购入账
        {
          path: 'purchase-receivables',
          name: 'purchaseReceivables',
          parentName: 'receivablesManage',
          fullPath: '/financialManagement/receivables-manage/purchase-receivables',
          component: () => import('@/views/financialManagement/SettlementManagement/List'),
          meta: { title: '采购入账', Identity: purchase },
          props: { Identity: purchase, status: '1'}
        },
        // 销售入账
        {
          path: 'sales-receivables',
          name: 'salesReceivables',
          parentName: 'receivablesManage',
          fullPath: '/financialManagement/receivables-manage/sales-receivables',
          component: () => import('@/views/financialManagement/SettlementManagement/List'),
          meta: { title: '销售入账', Identity: sell},
          props: { Identity: sell, status: '1'}
        }
      ]
    },
    // 付款管理
    {
      path: 'payment-manage',
      name: 'paymentManage',
      component: Empty,
      parentName: 'financialManagement',
      meta: {
        title: '付款管理',
      },
      children: [
        // 应付管理
        {
          path: 'payable-manage',
          name: 'payableManage',
          parentName: 'paymentManage',
          fullPath: '/financialManagement/payment-manage/payable-manage',
          component: () => import('@/views/financialManagement/PaymentManage/PayableManage'),
          meta: { title: '应付管理', Identity: purchase},
          props: { Identity: purchase}
        },
        // 付款详情
        {
          path: 'payable-manage/detail',
          name: 'payableManageDetail',
          parentName: 'paymentManage',
          fullPath: '/financialManagement/payment-manage/payable-manage/detail',
          component: () => import('@/views/financialManagement/PaymentManage/PayableManage/detail.vue'),
          meta: { title: '应付管理', Identity: purchase, activeMenu: '/financialManagement/payment-manage/payable-manage'},
          props: { Identity: purchase},
          isHide: true,
        },
        // 付款记录-线上
        {
          path: 'online-payment-record',
          name: 'onlinePaymentRecord',
          parentName: 'paymentManage',
          fullPath: '/financialManagement/payment-manage/online-payment-record',
          component: () => import('@/views/financialManagement/PaymentManage/PaymentRecord'),
          meta: { title: '付款记录', Identity: purchase },
          props: { Identity: purchase, payWay: 0} // payWay: 0线上，4线下
        },
        // 付款记录-线下
        // {
        //   path: 'offline-payment-record',
        //   name: 'offlinePaymentRecord',
        //   parentName: 'paymentManage',
        //   fullPath: '/financialManagement/payment-manage/offline-payment-record',
        //   component: () => import('@/views/financialManagement/PaymentManage/PaymentRecord'),
        //   meta: { title: '付款记录-线下', Identity: purchase },
        //   props: { Identity: purchase, payWay: 4}
        // },
        // 付款统计
        {
          path: 'payment-statistics',
          name: 'paymentStatistics',
          parentName: 'paymentManage',
          fullPath: '/financialManagement/payment-manage/payment-statistics',
          component: () => import('@/views/financialManagement/PaymentManage/PaymentStatistics'),
          meta: { title: '付款统计', Identity: purchase  },
          props: { Identity: purchase}
        }
      ]
    },
    // 收款管理
    {
      path: 'receive-manage',
      name: 'ReceiveManage',
      component: Empty,
      parentName: 'financialManagement',
      meta: {
        title: '收款管理',
      },
      children: [
        // 应收管理
        {
          path: 'receivable-manage',
          name: 'ReceivableManage',
          parentName: 'ReceiveManage',
          fullPath: '/financialManagement/receive-manage/receivable-manage',
          component: () => import('@/views/financialManagement/PaymentManage/PayableManage'),
          meta: { title: '应收管理', Identity: sell},
          props: { Identity: sell}
        },
        // 付款详情
        {
          path: 'receivable-manage/detail',
          name: 'receivableManageDetail',
          parentName: 'ReceiveManage',
          fullPath: '/financialManagement/receive-manage/receivable-manage/detail',
          component: () => import('@/views/financialManagement/PaymentManage/PayableManage/detail.vue'),
          meta: { title: '应付管理', Identity: sell, activeMenu: '/financialManagement/receive-manage/receivable-manage'},
          props: { Identity: sell},
          isHide: true,
        },
        // 付款记录-线上
        {
          path: 'online-receive-record',
          name: 'onlineReceiveRecord',
          parentName: 'ReceiveManage',
          fullPath: '/financialManagement/payment-manage/online-payment-record',
          component: () => import('@/views/financialManagement/PaymentManage/PaymentRecord'),
          meta: { title: '收款记录',Identity: sell},
          props: { Identity: sell,  payWay: 0, }
        },
        // 付款记录-线下
        // {
        //   path: 'offline-receive-record',
        //   name: 'offlineReceiveRecord',
        //   parentName: 'ReceiveManage',
        //   fullPath: '/financialManagement/payment-manage/offline-payment-record',
        //   component: () => import('@/views/financialManagement/PaymentManage/PaymentRecord'),
        //   meta: { title: '收款记录-线下',Identity: sell },
        //   props: { Identity: sell,  payWay: 4}
        // },
        // 付款统计
        {
          path: 'receive-statistics',
          name: 'receiveStatistics',
          parentName: 'ReceiveManage',
          fullPath: '/financialManagement/payment-manage/payment-statistics',
          component: () => import('@/views/financialManagement/PaymentManage/PaymentStatistics'),
          meta: { title: '收款统计', Identity: sell },
          props: { Identity: sell}
        }
      ]
    },
    {
      path: 'basic-setting',
      component:Empty,
      name: 'basicSetting',
      redirect: '/financialManagement/basic-setting/period-setting',
      parentName: 'financialManagement',
      meta: {
        title: '基础设置',
      },
      children: [
        {
          path: 'period-setting',
          fullPath: '/financialManagement/basic-setting/period-setting',
          component: () => import('@/views/financialManagement/basicSetting/periodSetting/index.vue'),
          parentName: 'basicSetting',
          name: 'periodSetting',
          meta: {
            title: '账期设置'
          }
        },
        {
          path: 'invoiceInfo',
          fullPath: '/financialManagement/basic-setting/invoiceInfo',
          component: () => import('@/views/financialManagement/basicSetting/invoiceInfo.vue'),
          parentName: 'basicSetting',
          name: 'periodSetting',
          meta: {
            title: '开票信息'
          }
        }
      ]
    }
  ]
}

export default financialManagementRouter