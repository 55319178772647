/**
 * 结算单模块接口
 */

import axios from "./request";
import config from '@/config/index'

// 订单列表
export const getOrderList = (params) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/finished/orders`,
    method: "get",
    params
  })
}
//结算单关联的订单列表
export const getOrderListByStatementId = (params) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/orders`,
    method: "get",
    params
  })
}

// 到货单列表
export const getArrivalList = (params) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/receives`,
    method: "get",
    params
  })
}

// 预生成结算单
export const previewStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/preview`,
    method: "post",
    data: data
  })
}

// 创建结算单
export const createStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/create`,
    method: "post",
    data: data
  })
}

// 给结算单添加订单
export const addOrderToStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/addOrder`,
    method: "put",
    data: data
  })
}

// 从结算单移除订单
export const removeOrderFromStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/removeOrder`,
    method: "put",
    data: data
  })
}

// 结算单分页查询
export const getStatementList = (params) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/page`,
    method: "get",
    params
  })
}

// 撤回结算单
export const withdrawStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/revoke`,
    method: "put",
    data
  })
}

// 确认结算单
export const confirmStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/confirm`,
    method: "put",
    data
  })
}

// 驳回结算单
export const rejectStatement = (data) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/reject`,
    method: "put",
    data
  })
}

// 结算单信息
export const getStatementInfo = (id) => {
  return axios.request({
    url: `${config.baseUrl}/settleStatement/info/${id}`,
    method: "get"
  })
}