const state = {
  identity: {
    purchase: 1,
    sell: 2
  },
  ExternalLink: process.env.VUE_APP_EXTERNAL_LINK,
  windowName: process.env.VUE_APP_WINDOW_NAME,
  name: process.env.VUE_APP_NAME,
}
export default{
  state
}