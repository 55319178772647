import Layout from '@/layout'
import store from '@/store';
const { purchase, sell } = store.state.app.identity

const contractRouter = {
  path: '/contract',
  component: Layout,
  name: 'Contract',
  meta: { title: '合同管理' },
  children: [
    {
      path: 'base',
      name: 'ContractBase',
      fullPath: '/contract/base',
      parentName: 'Contract',
      component: () => import('@/views/Contract/Base'),
      meta: { title: '基础设置' }
    },
    {
      path: 'signperson',
      name: 'ContractSignPerson',
      fullPath: '/contract/signperson',
      parentName: 'Contract',
      isHide: true,
      component: () => import('@/views/Contract/Base/SignPerson.vue'),
      meta: { title: '签章负责人', activeMenu: '/contract/base' }
    },
    {
      path: 'add',
      name: 'ContractBaseAdd',
      fullPath: '/contract/add',
      parentName: 'Contract',
      isHide: true,
      component: () => import('@/views/Contract/Base/add.vue'),
      meta: { title: '合同模版', activeMenu: '/contract/base', isPublic: true, activeMenu: '/contract/base' }
    },
    {
      path: 'purchase',
      name: 'ContractPurchase',
      fullPath: '/contract/purchase',
      parentName: 'Contract',
      component: () => import('@/views/Contract/Purchase'),
      meta: { title: '采购合同', Identity: purchase }
    },
    {
      path: 'purchase/:id',
      name: 'ContractDetail',
      fullPath: '/contract/purchase/:id',
      parentName: 'Contract',
      isHide: true,
      component: () => import('@/views/Contract/Purchase/detail.vue'),
      meta: { title: '采购合同', Identity: purchase, activeMenu: '/contract/purchase', isPublic: true, activeMenu: '/contract/purchase' },
      props: true
    },
    {
      path: 'sell',
      name: 'ContractSell',
      fullPath: '/contract/sell',
      parentName: 'Contract',
      component: () => import('@/views/Contract/Purchase'),
      meta: { title: '销售合同', Identity: sell }
    },
    {
      path: 'sell/:id',
      name: 'ContractSellDetail',
      fullPath: '/contract/sell/:id',
      parentName: 'Contract',
      isHide: true,
      component: () => import('@/views/Contract/Purchase/detail.vue'),
      meta: { title: '销售合同', Identity: sell, activeMenu: '/contract/sell', isPublic: true },
      props: true
    }
  ]
}

export default contractRouter