// 当前模块：API进行统一管理
import axios from "./request";
import config from '@/config/index'

// 生成付款单
export const createPaymentOrder = (data) => {
  return axios({
    url: `${config.baseUrl}/paymentOrder/generate`,
    method: "post",
    data,
  });
}

// 查询付款单列表
export const getPaymentOrderList = (params) => {
  return axios({
    url: `${config.baseUrl}/paymentOrder/page`,
    method: "get",
    params
  });
}

// 查询付款单详情
export const getPaymentOrderDetail = (params) => {
  return axios({
    url: `${config.baseUrl}/paymentOrder/detail`,
    method: "get",
    params
  });
}

// 付款单支持的支付方式
export const getPaymentOrderSupportPayways = (params) => {
  return axios({
    url: `${config.baseUrl}/paymentOrder/support/payways`,
    method: "get",
    params
  });
}

// 订单项交易明细
export const getPaymentOrderItemTradeDetails = (params) => {
  return axios({
    url: `${config.baseUrl}/paymentOrder/orderItemDetail`,
    method: "get",
    params
  });
}