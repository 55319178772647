<template>
  <div class="yl-title">
   {{ name }} <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'YlTitle',
   props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.yl-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  &::before {
    content: "";
    display: block;
    width: 4px;
    height: 16px;
    background: #E60012;
    margin-right: 5px;
  }
}
</style>