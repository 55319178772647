import http from "./request";

export function getGoodsCategoryTree(params) {
  return http({
    url: `power-cable-portal/goods/category/tree`,
    method: 'get',
    params
  })
}

/**
 * 商品分类树（包含大类）
 * @param {*} params 
 * @returns 
 */
export function getGoodsCategoryAllTree(params) {
  return http({
    url: `power-cable-portal/goods/category/all/tree`,
    method: 'get',
    params
  })
}

/**
 * 商品分页列表(卖家销售管理)
 * @param {*} params 
 * @returns 
 */
export function getGoodsList(params) {
  return http({
    url: `power-cable-portal/product/page/seller`,
    method: 'get',
    params
  })
}
/**
 * sku列表（不分页）
 * @param {*} params 
 * @returns 
 */

export function skuListNoPage(params) {
  return http({
    url: `power-cable-portal/sku/list`,
    method: 'get',
    params
  })
}
/**
 * 店铺主推保存(单条)
 * @param {*} params 
 * @returns 
 */
export function saveShopPromotion(data) {
  return http({url:`power-cable-portal/main/sale/save`,method:"post", data})
}

/**
 * 获取主推配置(所有)
 * @param {*} params 
 * @returns 
 */
export function getShopPromotionList(params) {
  return http({ url: `power-cable-portal/main/sale/all`, params })
}

/**
 * 获取主推配置(所有)
 * @param {Number|String} id 
 * @returns 
 */
export function deleteShopPromotion(id) {
  return http({ url: `power-cable-portal/main/sale/${id}`, method: 'delete' })
}

/**
 * 获取店铺信息
 * @returns 
 */
export function getShopInfo() {
  return http({ url: `power-cable-portal/shop/getInfo`, method: 'get' })
}

/**
 * 修改店铺头像和详情
 * @param { * } data 
 * @returns
 */
export function saveShopInfo(data){
  return http({
    url:'power-cable-portal/shop/save',
    method:'post',
    data
  })
}
