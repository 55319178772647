<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  computed: {
    // 获取当前路由的元信息中的title
    title() {
      return this.$route.meta.title;
    }
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb{
  margin: 5px 0 15px 0;
}
</style>