import Empty from '@/views/Empty.vue'

const waybillRouter = {
  path: '/waybill',
  component: Empty,
  isHide:true,
  name:"waybill",
  meta: { title: '配送单管理' , isPublic: true },
  children: [
    {
      path: 'delivery-note',
      name: 'DeliveryNote',
      parentName:'waybill',
      component: () => import('@/views/Waybill/DeliveryNote.vue'),
      meta: { title: '配送单' , isPublic: true }
    },
    {
      path: 'bill-lading',
      name: 'BillOfLading',
      parentName:'waybill',
      component: () => import('@/views/Waybill/BillOfLading.vue'),
      meta: { title: '自提单' , isPublic: true }
    },
  ]
}

export default waybillRouter