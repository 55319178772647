const state = {
  companyCertifyInfo: {},
  comTypeEnum: [
    { label: "有限责任公司", value: 1 },
    { label: "股份有限公司", value: 2 },
    { label: "有限合伙企业", value: 3 },
    { label: "个体工商户", value: 7 },
    { label: "其他有限公司", value: 8 },
  ],
  cardEnum: [
    { label: "身份证", value: 1 },
    { label: "港澳通行证", value: 3 },
    { label: "护照", value: 4 },
    { label: "台胞证", value: 5 },
  ],
};
const mutations = {};
const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
