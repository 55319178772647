import Layout from '@/layout'

const payRouter = {
  path: '/pay',
  component: Layout,
  name:'Pay',
  meta: { title: '支付' , isPublic: true},
  isHide:true,
  children: [
    {
      path: 'index/:id',
      isHide:true,
      name: 'PayIndex',
      parentName:'Pay',
      component: () => import('@/views/Pay/index.vue'),
      meta: { title: '支付' , isPublic: true}
    },
    {
      path: 'offline',
      name: 'Offline',
      fullPath:'/pay/offline',
      parentName:'Pay',
      isHide:true,
      component: () => import('@/views/Pay/Offline.vue'),
      meta: { title: '线下支付' , isPublic: true}
    },
    {
      path: 'online',
      name: 'Online',
      fullPath:'/pay/online',
      parentName:'Pay',
      isHide:true,
      component: () => import('@/views/Pay/Online.vue'),
      meta: { title: '线上支付' , isPublic: true}
    },
  ]
}

export default payRouter