const limitNumber = {
    inserted(el) {
      const inputNumber = el;
      if (inputNumber) {
        inputNumber.addEventListener('change', (event) => {
          const value = Number(event.target.value);
          if (!isNaN(value)) {
            const decimalPart = value.toString().split('.')[1];
            if (decimalPart) {
              event.target.value = String((Math.floor(Number(value) * 1000000))/1000000).replace(/0+$/, '');
            } else {
              event.target.value = value;
            }
          }
        });
      }
    },
  }
  export default limitNumber