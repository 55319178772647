<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    // 监听点击事件
    document.addEventListener('click', this.handleLinkClick);
    // 保存原始的window.open函数
    // 保存原始的window.open函数
    const originalWindowOpen = window.open.bind(window);
    // 重写window.open函数
    const _this = this
    window.open = function(url, name, features, replace) {
        // 跳转前处理链接地址
        // 检查是否为外链
        // 这里可以根据实际需求调整判断逻辑
        let newUrl
        if (url.startsWith('http')&&(url.includes('yilanwang')||url.includes('yl')||url.includes('localhost')||url.includes('127.0.0.1')||url.includes(':7000'))) {
          if (!(url.includes('?'))) {
            newUrl = url + '?token='+ _this.getToken;
          } else {
            newUrl = url + '&token='+ _this.getToken;
          }
        } else {
          newUrl = url;
        }
        const newWindow = originalWindowOpen(newUrl, name, features, replace);
        return newWindow;
    };
  },
  computed: {
    getToken() {
      return this.$store.getters['user/token'];
    }
  },
  methods: {
    handleLinkClick(event) {
      // 获取点击的元素
      const target = event.target;
      // 检查是否为外链
      if (this.isExternalLink(target)) {
        // 执行自定义跳转逻辑，例如：
        // 可以在这里加入具体的跳转逻辑，比如调用 this.$router.push 或 window.location.href
        window.location.href = target.href+'?token='+this.getToken;
        // 阻止默认的跳转行为
        event.preventDefault();
      }
    },
    isExternalLink(element) {
      // 检查目标元素是否为外链
      const href = element.getAttribute('href');
      if (href && href.startsWith('http')&&!(href.includes('?token'))&&(href.includes('yilanwang')||href.includes('yl')||href.includes('localhost')||href.includes('127.0.0.1')||href.includes(':7000'))) {
        // 这里可以根据实际需求调整判断逻辑
        return true;
      }
      return false;
    }
  }
}
</script>

