<template>
  <div class="top-bar">
    <div class="container">
      <div class="logo">
        <img @click="goHome()" src="./images/logo.png" alt="" />
        <span class="title">企业后台</span>
      </div>
      <div class="user-info">
        <!-- <el-avatar :src="userInfo.avatar||circleUrl"></el-avatar> -->
        <el-dropdown @command="handleCommand">
          <el-avatar :src="userInfo.avatar || circleUrl"></el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  command="AccountInfo">账号信息</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>{{ userInfo.main ? "管理员" : userInfo.roleName }}</span>
        <span>{{ userInfo.nickName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/store/mutation-types"
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    ExternalLink() {
      return this.$store.state.app.ExternalLink
    },
    windowName() {
      return this.$store.state.app.windowName
    }
  },
  methods:{
     handleCommand(name) {
        this.$router.push({name})
      },
      goHome() {
        location.href = this.ExternalLink + `?token=${getToken()}`
      }
  }
};
</script>

<style scoped lang="scss">
.top-bar {
  background: #fff;
  .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    > div {
      padding: 8px 0;
      display: flex;
      align-items: center;
    }
    .logo {
      .title {
        margin-left: 50px;
        font-size: 20px;
        color: #666;
      }
    }
    .user-info {
      font-size: 14px;
      color: #333;
      span {
        margin-left: 13px;
      }
    }
  }
}
</style>
