import Empty from "@/components/empty.vue";
import Layout from "@/layout";
import store from '@/store';
const { purchase, sell } = store.state.app.identity
export default {
  path: "/logistics",
  name: "Logistics",
  component: Layout,
  meta: { title: "物流管理" },
  children: [
    // 发货管理
    {
      path: "delivery",
      component: Empty,
      name: "Delivery",
      parentName: "Logistics",
      meta: { title: "发货管理" },
      children: [
        {
          // 订单发货
          path: "order",
          name: "DeliveryOrder",
          parentName: "Delivery",
          fullPath: "/logistics/delivery/order",
          component: () => import("@/views/Logistics/Delivery/order.vue"),
          meta: { title: "订单发货" },
        },
        {
          // 发货详情
          path: "orderDetail",
          name: "DeliveryOrderDetail",
          parentName: "Delivery",
          isHide: true,
          fullPath: "/logistics/delivery/orderDetail",
          component: () => import("@/views/Logistics/Delivery/orderDetail.vue"),
          meta: { title: "发货预览", activeMenu: "/logistics/delivery/order" },
        },
        {
          // 发货单
          path: "deliveryList",
          name: "DeliveryList",
          parentName: "Delivery",
          fullPath: "/logistics/delivery/deliveryList",
          component: () => import("@/views/Logistics/Delivery/deliveryList.vue"),
          meta: { title: "发货单", Identity: sell },
        },
        {
          // 发货单详情
          path: "deliveryDetail",
          name: "DeliveryDetail",
          parentName: "Delivery",
          isHide: true,
          fullPath: "/logistics/delivery/deliveryDetail",
          component: () => import("@/views/Logistics/Delivery/deliveryDetail.vue"),
          meta: {
            title: "发货单详情",
            activeMenu: "/logistics/delivery/deliveryList",
          },  
        },
        {
          // 物流跟踪
          path: "logistics",
          name: "DeliveryLogistics",
          parentName: "Delivery",
          isHide: true,
          fullPath: "/logistics/delivery/logistics",
          component: () => import("@/views/Logistics/Delivery/logistics.vue"),
          meta: {
            title: "物流信息维护",
            activeMenu: "/logistics/delivery/deliveryList",
          },
        },
        
      ],
    },
    // 收货管理
    {
      path: "collect",
      component: Empty,
      name: "Collect",
      parentName: "Logistics",
      meta: { title: "收货管理" },
      children: [
        {
          // 订单收货
          path: "order",
          name: "CollectOrder",
          parentName: "Collect",
          fullPath: "/logistics/collect/order",
          component: () => import("@/views/Logistics/Collect/order.vue"),
          meta: { title: "订单收货", Identity: purchase },
        },
        {
          // 收货详情
          path: "orderDetail",
          name: "CollectOrderDetail",
          parentName: "Collect",
          isHide: true,
          fullPath: "/logistics/collect/orderDetail",
          component: () => import("@/views/Logistics/Collect/orderDetail.vue"),
          meta: { title: "收货详情", activeMenu: "/logistics/collect/order" },
        },
        {
          // 物流跟踪
          path: "logistics",
          name: "CollectLogistics",
          parentName: "Collect",
          isHide: true,
          fullPath: "/logistics/collect/logistics",
          component: () => import("@/views/Logistics/Collect/logistics.vue"),
          meta: { title: "物流信息", activeMenu: "/logistics/collect/order" },
        },
        {
          // 收货单
          path: "collectList",
          name: "CollectList",
          parentName: "Collect",
          fullPath: "/logistics/collect/collectList",
          component: () => import("@/views/Logistics/Collect/collectList.vue"),
          meta: { title: "收货单" },
        },
        {
          // 收货单详情
          path: "collectDetail",
          name: "CollectDetail",
          parentName: "Collect",
          isHide: true,
          fullPath: "/logistics/collect/collectDetail",
          component: () => import("@/views/Logistics/Collect/collectDetail.vue"),
          meta: { title: "收货单详情", activeMenu: "/logistics/collect/collectList" },
        }
      ]
    },
    // 备货管理
    {
      path: "stock",
      component: Empty,
      name: "Stock",
      parentName: "Logistics",
      meta: { title: "备货管理" },
      children: [
        // 订单备货
        {
          path: "order",
          name: "StockOrder",
          parentName: "Stock",
          fullPath: "/logistics/stock/order",
          component: () => import("@/views/Logistics/Stock/order.vue"),
          meta: { title: "订单备货" },
        },
        // 订单备货详情
        {
          path: "orderDetail",
          name: "StockOrderDetail",
          parentName: "Stock",
          isHide: true,
          fullPath: "/logistics/stock/orderDetail",
          component: () => import("@/views/Logistics/Stock/orderDetail.vue"),
          meta: { title: "备货预览", activeMenu: "/logistics/stock/order" },
        },
        // 提货通知
        {
          path: "notice",
          name: "StockNotice",
          parentName: "Stock",
          fullPath: "/logistics/stock/notice",
          component: () => import("@/views/Logistics/Stock/notice.vue"),
          meta: { title: "提货通知", Identity: sell },
        },
        // 提货通知详情
        {
          path: "noticeDetail",
          name: "StockNoticeDetail",
          parentName: "Stock",
          isHide: true,
          fullPath: "/logistics/stock/noticeDetail",
          component: () => import("@/views/Logistics/Stock/noticeDetail.vue"),
          meta: { title: "提货通知详情", activeMenu: "/logistics/stock/notice" },
        },
        // 提货单列表
        {
          path: "bill",
          name: "StockBill",
          parentName: "Stock",
          fullPath: "/logistics/stock/bill",
          component: () => import("@/views/Logistics/Stock/bill.vue"),
          meta: { title: "提货单", Identity: sell },
        },
        // 提货单详情
        {
          path: "billDetail",
          name: "StockBillDetail",
          parentName: "Stock",
          isHide: true,
          fullPath: "/logistics/stock/billDetail",
          component: () => import("@/views/Logistics/Stock/billDetail.vue"),
          meta: { title: "提货单详情", activeMenu: "/logistics/stock/bill" },
        },
        // 确认提货
        {
          path: "billConfirm",
          name: "StockBillConfirm",
          parentName: "Stock",
          isHide: true,
          fullPath: "/logistics/stock/billConfirm",
          component: () => import("@/views/Logistics/Stock/billConfirm.vue"),
          meta: { title: "确认提货", activeMenu: "/logistics/stock/bill" },
        }
      ]
    },
    // 提货管理
    {
      path: "pick",
      component: Empty,
      name: "Pick",
      parentName: "Logistics",
      meta: { title: "提货管理" },
      children: [
        // 订单提货
        {
          path: "order",
          name: "PickOrder",
          parentName: "Pick",
          fullPath: "/logistics/pick/order",
          component: () => import("@/views/Logistics/Pick/order.vue"),
          meta: { title: "订单提货", Identity: purchase },
        },
        // 生成提货单
        {
          path: "createBill",
          name: "PickCreateBill",
          parentName: "Pick",
          isHide: true,
          fullPath: "/logistics/pick/createBill",
          component: () => import("@/views/Logistics/Pick/createBill.vue"),
          meta: { title: "生成提货单", activeMenu: "/logistics/pick/order" },
        },
        // 提货单
        {
          path: "bill",
          name: "PickBill",
          parentName: "Pick",
          fullPath: "/logistics/pick/bill",
          component: () => import("@/views/Logistics/Pick/bill.vue"),
          meta: { title: "提货单", Identity: purchase },
        },
        // 提货单详情
        {
          path: "billDetail",
          name: "PickBillDetail",
          parentName: "Pick",
          isHide: true,
          fullPath: "/logistics/pick/billDetail",
          component: () => import("@/views/Logistics/Pick/billDetail.vue"),
          meta: { title: "提货单详情", activeMenu: "/logistics/pick/bill" },
        }
      ]
    },
    {
      path: "basic",
      component: Empty,
      name: "Basic",
      parentName: "Logistics",
      meta: { title: "基础设置" },
      children: [
        {
          path: "deliveryList",
          name: "BasicDeliveryList",
          parentName: "Basic",
          fullPath: "/logistics/basic/deliveryList",
          component: () => import("@/views/Logistics/Base/Delivery/index.vue"),
          meta: { title: "提货地址" },
        },
        {
          path: "deliveryDetail",
          name: "BasicDeliveryDetail",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/deliveryDetail",
          component: () => import("@/views/Logistics/Base/Delivery/detail.vue"),
          meta: {
            title: "提货地址详情",
            activeMenu: "/logistics/basic/deliveryList",
            isPublic: true,
          },
        },
        {
          path: "collectList",
          name: "BasicCollectList",
          parentName: "Basic",
          fullPath: "/logistics/basic/collectList",
          component: () => import("@/views/Logistics/Base/Collect/index.vue"),
          meta: { title: "收货地址" },
        },
        {
          path: "collectDetail",
          name: "BasicCollectDetail",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/collectDetail",
          component: () => import("@/views/Logistics/Base/Collect/detail.vue"),
          meta: {
            title: "收货地址详情",
            activeMenu: "/logistics/basic/collectList",
            isPublic: true,
          },
        },
        {
          path: "consigneeManage",
          name: "BasicConsigneeManage",
          parentName: "Basic",
          fullPath: "/logistics/basic/consigneeManage",
          component: () => import("@/views/Logistics/Base/Consignee/index.vue"),
          meta: { title: "提货人" },
        },
        {
          path: "consigneeEdit",
          name: "BasicConsigneeEdit",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/consigneeEdit",
          component: () => import("@/views/Logistics/Base/Consignee/edit.vue"),
          meta: {
            title: "提货人详情",
            activeMenu: "/logistics/basic/consigneeManage",
            isPublic: true,
          },
        },
        {
          path: "senderManage",
          name: "BasicSenderManage",
          parentName: "Basic",
          fullPath: "/logistics/basic/senderManage",
          component: () => import("@/views/Logistics/Base/Sender/index.vue"),
          meta: { title: "送货人" },
        },
        {
          path: "senderEdit",
          name: "BasicSenderEdit",
          parentName: "Basic",
          isHide: true,
          fullPath: "/logistics/basic/senderEdit",
          component: () => import("@/views/Logistics/Base/Sender/edit.vue"),
          meta: {
            title: "送货人详情",
            activeMenu: "/logistics/basic/senderManage",
            isPublic: true,
          },
        },
      ],
    },
    {
      path: 'way',
      component: Empty,
      name: 'way',
      parentName: 'Logistics',
      meta: { title: '运单管理' },
      children: [
      //   {
      //   path: 'sellerList',
      //   name: 'SellerList',
      //   parentName: 'way',
      //   fullPath: '/logistics/way/sellerList',
      //   component: () => import('@/views/Logistics/OfflineWaybill/seller.vue'),
      //   meta: { title: '销售运单' }
      // },
      // {
      //   path: 'purchaseList',
      //   name: 'PurchaseList',
      //   parentName: 'way',
      //   fullPath: '/logistics/way/purchaseList',
      //   component: () => import('@/views/Logistics/OfflineWaybill/purchase.vue'),
      //   meta: { title: '采购运单' }
      // },
      {
        path: 'logisticsOrder',
        name: 'LogisticsOrder',
        parentName: 'way',
        fullPath: '/logistics/way/logisticsOrder',
        component: () => import('@/views/Logistics/LogisticsOrder/index.vue'),
        meta: { title: '物流订单' }
      },
      {
        path: 'logisticsDetail',
        name: 'LogisticsDetail',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/logisticsDetail',
        component: () => import('@/views/Logistics/LogisticsOrder/detail.vue'),
        meta: { title: '物流订单详情' }
      },
      {
        path: 'place',
        name: 'Place',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/logisticsDetail',
        component: () => import('@/views/Logistics/LogisticsOrder/place.vue'),
        meta: { title: '物流下单' }
      },
      {
        path: 'edit',
        name: 'logisticsEdit',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/logisticsDetail',
        component: () => import('@/views/Logistics/LogisticsOrder/edit.vue'),
        meta: { title: '物流订单详情' }
      },
      {
        path: 'onlineWaybillList',
        name: 'OnlineWaybillList',
        parentName: 'way',
        fullPath: '/logistics/way/onlineWaybillList',
        component: () => import('@/views/Logistics/OnlineWaybill/list.vue'),
        meta: { title: '物流承运单' }
      },
      {
        path: 'onlineWaybill',
        name: 'OnlineWaybill',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/onlineWaybill',
        component: () => import('@/views/Logistics/OnlineWaybill/index.vue'),
        meta: { title: '承运详情',activeMenu: '/logistics/way/onlineWaybill' }
      },
      {
        path: 'logisticsMap',
        name: 'logisticsMap',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/logisticsMap',
        component: () => import('@/views/Logistics/OnlineWaybill/logisticsMap.vue'),
        meta: { title: '运单信息', isPublic: true , activeMenu: '/logistics/way/onlineWaybill' }
      },
      {
        path: 'payList',
        name: 'PayList',
        parentName: 'way',
        fullPath: '/logistics/way/payList',
        component: () => import('@/views/Logistics/OnlineWaybill/payList.vue'),
        meta: { title: '运费管理', isPublic: true , activeMenu: '/logistics/way/onlineWaybill' }
      },
      {
        path: 'wayPay',
        name: 'WayPay',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/logisticsMap',
        component: () => import('@/views/Logistics/OnlineWaybill/pay.vue'),
        meta: { title: '运单支付', isPublic: true , activeMenu: '/logistics/way/onlineWaybill' }
      },
      {
        path: 'logisticsInvoice',
        name: 'LogisticsInvoice',
        parentName: 'way',
        fullPath: '/logistics/way/logisticsInvoice',
        component: () => import('@/views/Logistics/OnlineWaybill/logisticsInvoice.vue'),
        meta: { title: '物流发票', isPublic: true , activeMenu: '/logistics/way/onlineWaybill' }
      },
      {
        path: 'invoiceRecords',
        name: 'InvoiceRecords',
        parentName: 'way',
        isHide: true,
        fullPath: '/logistics/way/invoiceRecords',
        component: () => import('@/views/Logistics/OnlineWaybill/invoiceRecords.vue'),
        meta: { title: '申请记录', isPublic: true , activeMenu: '/logistics/way/onlineWaybill' }
      },
    ]
    },
  ],
};
