import axios from "./request";
import config from '@/config/index'
let {POWER_CABLE_BASE} = config;

/**
 * 获取消息列表
 * @param {*} params 
 * @returns 
 */
export const getMessageList = (params) => {
  return axios({
    url: '/power-cable-base/msg/page',
    method: 'get',
    params
  })
}

/**
 * 消息配置列表
 * @returns 
 */
export  function getMessageConfigList() {
  return axios({
    url: `power-cable-base/msg/config/all`,
    method: 'get',
  })
}

// 查看消息
export  function getMessage(id) {
  return axios({
    url: `${POWER_CABLE_BASE}/msg/read/${id}`,
    method: 'get',
  })
}