// 当前模块：API进行统一管理
import axios from "./request";
import config from '@/config/index'

// /api/product/getBaseCategoryList

export const reqOrderList = (params) => {
	return axios({
		url: `${config.baseUrl}/order/page`,
		method: 'get',
		params
	})
}

/**
 * 合同列表
 * @param {*} params
 * @returns
 */
export const getOrderContractPage = (params) => {
	return axios({
		url: `${config.baseUrl}/order/contract/page`,
		method: 'get',
		params
	})
}

/**
 * 订单详情
 * @param {*} orderId
 * @returns
 */
export function getOrderDetail(orderId) {
	return axios({
		url: `${config.baseUrl}/order/detail/${orderId}`,
		method: 'get'
	})
}

/**
 * 订单删除
 * @param {*} id
 * @returns
 */
export function deleteOrder(id, status=false) {
	return axios({
		url: `${config.baseUrl}/order/${id}`,
		method: 'delete',
		params: {
			removeContract: status
		}
	})
}

//确认订单合同内容（版本2.0）
export function confirmContactVersonTwo(data) {
	return axios({
		url: `${config.baseUrl}/order/contract/confirm/v2`,
		method: 'post',
		data
	})
}

//确认收货
export function getOrderReceipt(params) {
	return axios({
		url: `${config.baseUrl}/order/distribution/receipt`,
		method: 'put',
		params
	})
}

// 确认支付，线上支付，输入密码
export function orderPay(data) {
	return axios({
		url: `${config.baseUrl}/trade/pay`,
		method: 'post',
		data
	})
}

/**
 * 暂不支付，跳过支付
 * @param {*} orderId
 * @returns
 */
export function orderPaySkip(orderId) {
	return axios({
		url: `${config.baseUrl}/order/pay/skip/${orderId}`,
		method: 'put',
	})
}

/**
 * 根据订单id获取合同模板列表
 * @param {*} params
 * @returns
 */
export function getContractListById(params) {
	return axios({
		url: `${config.baseUrl}/order/contract/templates/${params.orderId}`,
		method: 'get',
		params: params
	})
}

// 订单支持的支付方式
export function orderPaySupport(params) {
	return axios({
		url: `${config.baseUrl}/order/pay/support/${params.orderId}`,
		method: 'get',
		params
	})
}

//合同详情
export function getContractDetail(id) {
	return axios({
		url: `${config.baseUrl}/order/contract/detail/${id}`,
		method: 'get'
	})
}

//去支付,生成支付订单
export function getPayOrder(data) {
	return axios({
		url: `${config.baseUrl}/order/pay/go`,
		method: 'post',
		data
	})
}

// 确认支付结果
export function orderPayValidate(id, status) {
	let params = {}
	if (status) {
		params = {
			status
		}
	}
	return axios({
		url: `${config.baseUrl}/trade/validate/${id}`,
		params,
		method: 'PUT'
	})
}

// 订单发货
export function orderSend(params) {
	return axios({
		url: `${config.baseUrl}/order/distribution/send`,
		method: 'put',
		params
	})
}

// 修改订单价格

export function updateOrderPrice(data) {
	return axios({
		url: `${config.baseUrl}/price/confer/modify`,
		method: 'post',
		data
	})
}

// 产生过交易的合作企业
export function getOrderPartners(params) {
	return axios({
		url: `${config.baseUrl}/order/partners`,
		method: 'get',
		params
	})
}

// 订单明细列表
export function getOrderDetailList(params) {
	return axios({
		url: `${config.baseUrl}/order/items`,
		method: 'get',
		params
	})
}

// 运营后台上游供应商和下游采购方
export function getOrderPartnersList(params) {
	return axios({
		url: `${config.baseUrl}/order/transaction`,
		method: 'get',
		params
	})
}

// 计算收货价格差
export function getOrderPriceDiff(params) {
	return axios({
		url: `${config.baseUrl}/order/distribution/diff`,
		method: 'get',
		params
	})
}

// 作废合同
export function invalidContract(sn) {
  return axios({
    url: `${config.baseUrl}/order/contract/cancel/${sn}`,
    method: 'put'
  })
}
 // 撤回合同
 export function withdrawContract(sn) {
   return axios({
     url: `${config.baseUrl}/order/contract/recall/${sn}`,
     method: 'put'
   })
 }

 // 删除合同
 export function deleteContract(id, status) {
  return axios({
		url: `${config.baseUrl}/order/contract/delete/${id}`,
		method: 'delete',
		params: {
			removeOrder: status
		}
  })
 }