<template>
  <div class="app-wrapper">
    <Header/>
    <TopBar/>
    <div class="main">
      <div class="sidebar">
        <Sidebar/>
      </div>
      <div class="app-view">
        <YlBreadcrumb />
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import TopBar from '@/components/TopBar'
import Sidebar from '@/components/Sidebar'
import YlBreadcrumb from '@/components/YlBreadcrumb'
export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    TopBar,
    Sidebar,
    YlBreadcrumb
  }
}
</script>

<style scoped lang="scss">
.app-wrapper{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #F6F6F6;
  .headers{
    flex-grow: 0;
    flex-shrink: 0;
  }
  .footer-view{
    flex-grow: 0;
    flex-shrink: 0;
  }
  .main{
    width: 1200px;
    margin: 0 auto 20px;
    flex-grow: 1;
    display: flex;

    .sidebar{
      padding: 10px 0;
      width: 150px;
      margin-right: 20px;
      background-color: #fff;
    }
    .app-view{
      width: 1040px;
      margin-top: 10px;
    }
  }
}
</style>
