import { getGoodsCategoryAllTree as getGoodsCateTree } from '@/api/goods'
import store from '@/store'
const state = {
  // 商品分类树
  goodsCateTree: [],
}

const mutations = {
  SET_GOODS_CATE_TREE(state, goodsCateTree) {
    state.goodsCateTree = goodsCateTree
  }
}

const actions = {
  // 获取商品分类树
  getGoodsCateTree({ commit }) {
    return new Promise(resolve => {
      getGoodsCateTree().then(res => {
        commit('SET_GOODS_CATE_TREE', res.data)
        resolve()
      })
    })
  }
}
const getters = {
  goodsCateTree: (state) => {
    // 获取商品分类树
    if (!state.goodsCateTree.length) {
      store.dispatch('goods/getGoodsCateTree').then(() => {
        return state.goodsCateTree
      })
    }
    return state.goodsCateTree
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
