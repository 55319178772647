import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import './element-variables.scss'
// 引入路由
import router from '@/router'
// 引入仓库
import store from './store';
import Storage from 'vue-ls';
import * as filters from './filters' // global filters
Vue.use(Storage, {
  namespace: 'ylw_',
  name: 'ls',
  storage: 'local'
})
Vue.use(ElementUI);
// 引入mockServe.js
// import '@/mock/mockServe'
import './permission';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
import rules from '@/utils/rules';

Vue.prototype.$rules = rules
import Directives from './directive/index'
Vue.use(Directives)

import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

window._AMapSecurityConfig = {
  securityJsCode:'c55f3a3064e7063901524059b5fd6509',
}

import TypeNav from '@/components/YlTitle'
import YlInputNumber from '@/components/YlInputNumber';
Vue.component(TypeNav.name, TypeNav)
Vue.component(YlInputNumber.name, YlInputNumber)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  mounted() {
    window.name = this.$store.state.app.name
  }
}).$mount('#app')
