import axios from "./request";

/**
 * 获取角色管理列表(分页)
 * @param {*} params 
 * @returns 
 */
export function getRoleList(params){
    return axios({
        url:'power-cable-portal/role/page',
        method:'get',
        params
    })
}

/**
 * 获取角色管理列表(不分页)
 * @returns 
 */
export function getRolePage(){
    return axios({
        url:'power-cable-portal/role/list',
        method:'get'
    })
}

/**
 * 保存
 * @param {*} data 
 * @returns 
 */
export function saveRole(data){
    return axios({
        url:'power-cable-portal/role/save',
        method:'post',
        data
    })
}

/**
 * 删除
 * @param {*} id 
 * @returns 
 */
export function delRole(id){
    return axios({
        url:'power-cable-portal/role/' + id,
        method:'delete'
    })
}

/**
 * 启用禁用
 * @param {*} id 
 * @returns 
 */
export function changeEnableRole(id,status){
    return axios({
        url:`power-cable-portal/role/enable/${id}/${status}`,
        method:'put'
    })
}

/**
 * 获取指定角色的资源授权配置
 * @param {*} params 
 * @returns 
 */
export function getRoleResources(params){
    return axios({
        url:'power-cable-portal/permission/role/resources',
        method:'get',
        params
    })
}

/**
 * 获取登录用户的资源授权配置
 * @returns 
 */
export function getUserResources(){
    return axios({
        url:'power-cable-portal/permission/user/resources',
        method:'get'
    })
}

/**
 * 授予权限
 * @param {*} params 
 * @returns 
 */
export function saveGrantPermission(data){
    return axios({
        url:'power-cable-portal/permission/grant',
        method:'post',
        data
    })
}