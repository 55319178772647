import { reqOrderList,  getOrderDetail} from '@/api/order'
// order模块仓库
const state = {
  activeName: 'first',
  orderList: [],
  total: 0,
  statusFilterEnum: {
    0: {label: '待确认', badge: 1},
    1: {label: '待签合同', badge: 1},
    2: {label: '待付款', badge: 1},
    3: {label: '待收货', badge: 1},
    4: {label: '待评价', badge: 1},
  },
  statusEnum: {
    0: '待签合同',
    1: '待付款',
    2: '待发货',
    30: '待收货',
    4: '已完成',
    5: '已过期',
    6: '无效订单'
  },
  orderDetail: {}
}
const mutations = {
  ORDERLIST(state, orderList){
    state.orderList = orderList
  },
  ORDERTOTAL(state, total){
    state.total = total
  },
  SET_ORDER_DETAIL(state, detail){
    state.orderDetail = detail
  },
  CLEAR_ORDER_DETAIL(state){
    state.orderDetail = {}
    state.activeName = 'first'
  },
  SET_ACTIVE_NAME(state, activeName){
    state.activeName = activeName
  }
}
const actions = {
  // 获取订单列表
  async getOrderList({commit}, params) {
    let result = await reqOrderList(params)
    if (result.code == 0) {
      commit('ORDERLIST', result.data.records)
      commit('ORDERTOTAL', result.data.total)
    }
  },
  async getOrderDetail({commit}, id) {
    let result = await getOrderDetail(id)
    if (result.code == 0) {
      commit('SET_ORDER_DETAIL', result.data)
    }
  }
}
const getters = {
  id(state) {
    return state.orderDetail.id
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}