import { getPaymentOrderDetail } from'@/api/paymentOrder'
import { getOrderListByStatementId } from '@/api/settleStatement'
// 付款管理仓库
const state = {
  paymentDetail: [],
  orderList: []
}
const mutations = {
  // 付款管理详情
  setPaymentDetail(state, data) {
    state.paymentDetail = data
  },
  // 结算单明细(订单列表)
  setOrderList(state, data) {
    state.orderList = data
  },
  // 清除数据
  clearPayment(state) {
    state.paymentDetail = []
    state.orderList = []
  }
}

const actions = {
  // 付款管理列表
  async getPaymentOrderDetail({ commit }, params) {
    const res = await getPaymentOrderDetail(params)
    commit('setPaymentDetail', res.data)
  },
  // 结算单明细(订单列表)
  async getOrderListByStatementId({ commit }, params) {
    const res = await getOrderListByStatementId(params)
    commit('setOrderList', res.data.records)
    return res
  }
}

const getters = {
  // 付款管理列表
  getPaymentList: state => {
    // 付款单信息列表
    return state.paymentDetail.map(item => {
      return {
        ...item
      }
    })
  },
  // 付款管理详情
  getStatements: state => {
    let ary = []
    state.paymentDetail.forEach(item => {
      ary.push(...item.statements)
    })
    return ary
  },
  // 结算单明细(订单列表)
  getOrderList: state => {
    return state.orderList
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}