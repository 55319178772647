import { publicRoutes, asyncRoutes } from '@/router/index'
import { getUserResources } from '@/api/role'
import { restoreTree , handleTree } from '@/utils'

const state = {
  // routes: [],
  addRoutes: [],
  userResources: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    console.log(routes, '==============================>')
    // state.routes = publicRoutes.concat(routes)
  },
  SET_RESOURCES: (state, resources) => {
    state.userResources = resources
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      getUserResources().then(res => {
        let allAsyncRoutes = handleTree(asyncRoutes);
        let routes = allAsyncRoutes.filter(it => {
          return res.data.includes(it.name) || it.meta?.isPublic
        })
        let result = restoreTree(routes)
        commit('SET_ROUTES', result)
        commit('SET_RESOURCES', res.data)
        resolve(result)
      })
    })
  }
}
const getters = {
  getRoutes(state) {
    return state.addRoutes
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
