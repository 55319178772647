
import debounce from "./debounce";
import throttle from "./throttle"
import limitNumber from './limitNumber'
import clampy from '@clampy-js/vue-clampy';
import hasPermission from './hasPermission'
const directives = { debounce, throttle, clampy , limitNumber, hasPermission}
export default{
  install(Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}