import Layout from '@/layout'
import store from '@/store';
const { purchase, sell } = store.state.app.identity

const orderRouter = {
  path: '/order',
  component: Layout,
  name: 'Order',
  meta: { title: '订单管理' },
  children: [
    {
      path: 'purchase',
      name: 'OrderPurchase',
      fullPath: '/order/purchase',
      parentName: 'Order',
      component: () => import('@/views/Order/index.vue'),
      meta: { title: '采购订单', Identity: purchase },
    },
    {
      path: 'purchase/detail/:id',
      name: 'OrderDetail',
      fullPath: '/order/purchase/detail/:id',
      parentName: 'Order',
      isHide: true,
      component: () => import('@/views/Order/detail.vue'),
      meta: { title: '采购详情', Identity: purchase, activeMenu: '/order/purchase', isPublic: true },
      props: route => ({ id: route.params.id, Identity: purchase })
    },
    {
      path: 'sell',
      name: 'OrderSell',
      fullPath: '/order/sell',
      parentName: 'Order',
      component: () => import('@/views/Order/index.vue'),
      meta: { title: '销售订单', Identity: sell },
    },
    {
      path: 'sell/detail/:id',
      name: 'OrderSellDetail',
      fullPath: '/order/sell/detail/:id',
      parentName: 'Order',
      isHide: true,
      component: () => import('@/views/Order/detail.vue'),
      meta: { title: '销售详情', Identity: sell, activeMenu: '/order/sell', isPublic: true },
      props: route => ({ id: route.params.id, Identity: sell })
    }
  ]
}

export default orderRouter