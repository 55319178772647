<template>
  <div>
    <div v-for="(item, index) in nodes" :key="index + ''">
      <template v-if="item.children && item.children.length && !item.isHide">
        <el-submenu :index="item.fullPath || index + 11 + ''">
          <span slot="title">{{ item.meta?.title }}</span>
          <submenu-item :nodes="item.children"></submenu-item>
        </el-submenu>
      </template>
      <template v-else-if=" item.meta && item.meta.title && !item.isHide">
        <el-menu-item :index="item.fullPath">
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "submenuItem",
  props: ["nodes"],
};
</script>
