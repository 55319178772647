
import store from "@/store"

const hasPermission = {
  inserted: function (el, binding) {
    const permissions = store.state.permission.userResources
    const { value } = binding
    if (value) {
      if (!permissions.includes(value)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
export default hasPermission