import store from '@/store';
const { sell } = store.state.app.identity;
// 基础信息管理
import Layout from '@/layout'
export default {
  path: '/basicInfo',
  component: Layout,
  name: 'basicInfo',
  meta: { title: '基础信息管理', Identity: sell },
  children: [
    {
      path: 'subAccount',
      name: 'SubAccount',
      parentName: 'basicInfo',
      fullPath: '/basicInfo/subAccount',
      component: () => import('@/views/BasicInfo/SubAccount'),
      meta: { title: '子账号与角色' }
    },
    {
      path: 'rolesManage',
      name: 'RolesManage',
      parentName: 'basicInfo',
      fullPath: '/basicInfo/rolesManage',
      component: () => import('@/views/BasicInfo/RolesManage'),
      meta: { title: '角色管理' }
    }
  ]
}