import Layout from '@/layout'

const companyRouter = {
  path: '/company',
  component: Layout,
  name:'Company',
  meta: { title: '企业信息管理' },
  children: [
    {
      path: 'authInfo',
      name: 'AuthInfo',
      fullPath:'/company/authInfo',
      parentName:'Company',
      component: () => import('@/views/Company/AuthInfo'),
      meta: { title: '认证信息' }
    },
    {
      path: 'certification-record',
      name: 'CertificationRecord',
      fullPath:'/company/certification-record',
      parentName:'Company',
      isHide: true,
      component: () => import('@/views/Company/CertificationRecord'),
      meta: { title: '认证记录' }
    },
    {
      path: 'info',
      name: 'YellowPages',
      fullPath:'/company/info',
      parentName:'Company',
      component: () => import('@/views/Company/Info'),
      meta: { title: '企业黄页' }
    },
    {
      path: 'notice',
      name: 'Notice',
      fullPath:'/company/notice',
      parentName:'Company', 
      component: () => import('@/views/Company/Notice'),
      meta: { title: '企业公告' }
    }
  ]
}

export default companyRouter